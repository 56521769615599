<template>
  <div>
    <div
      :class="
            [isFilter
              ? 'modal-wrapper filter fixed left-0 right-0 top-0 bottom-0 z-9999'
              : 'modal-wrapper none-filter fixed z-9999 top-64 left-p-50 top-p-50 trans-50 wp-100 maxw-600 all-ease',
              {active: showModal}, extraClass]
          "
    >
      <div class="modal-container bg-white">
        <div class="modal-header relative bd-b-1 bd-gray-medium">
          <div class="modal-title fs-24 fw-b">
            <slot name="header">Default header</slot>
          </div>
          <button class="modal-close hand" @click="closeModal">
            <img alt='Modal close' :src="`${ASSETS_CDN}/vectors/general/icon-close-new.svg`" >
          </button>
        </div>
        <div
          :class="
                isFilter
                  ? 'modal-body custom-option pt-20 pb-20 pl-20 pr-20 overflow-auto'
                  : 'modal-body custom-option gray-bar pt-20 pb-20 pl-20 pr-20 overflow-auto hp-100 maxh-500'
              "

        >
          <slot name="body"> Default body </slot>
        </div>
        <div class="modal-footer clearfix bd-t-1 bd-gray-medium" v-if='showFooter'>
          <slot name="footer"> Default footer </slot>
        </div>
      </div>
    </div>
    <div
      :class="{active: showModal}"
      ref="modal-mask"
      class="modal-mask fixed z-9998 top-0 left-0 hp-100 wp-100"
      @click="closeModal"
    ></div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import { PHONE, DESKTOP } from '~/constant/common'
import { onBeforeRouteLeave } from 'vue-router'
import {assetCDN} from '~/helper'

export default {
  name: 'AppModal',
  emits: ['closeModal'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    isHome: {
      default: false,
      type: Boolean,
    },
    showFooter: {
      default: true,
      type: Boolean,
    },
    extraClass: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const showModal = ref(false)
    const ASSETS_CDN = assetCDN()
    const closeModal = () => {
      emit('closeModal', false)
    }

    watch(
      () => props.show,
      (show) => {
        showModal.value = show
        if (show === true) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = ''
        }
      },
    )

    const onResize = () => {
      if (!props.isHome && props.isFilter) {
        if (window.innerWidth > PHONE) {
          showModal.value = false
          emit('closeModal', false)
        }
      }
      if (props.isHome && props.isFilter) {
        if (window.innerWidth > DESKTOP) {
          showModal.value = false
          emit('closeModal', false)
        }
      }
    }
    onMounted(() => {
      window.addEventListener('resize', onResize)
    })

    onBeforeRouteLeave(()=>{
      document.body.style.overflow = ''
    })

    onMounted(()=>{
      window.addEventListener("orientationchange", function() {
        const target = document.getElementsByClassName('modal-body')
        for(let i = 0; i < target.length; i++){
          if (window.matchMedia("(orientation: portrait)").matches) {
            target[i].classList.remove("h-500")
            target[i].classList.add("h-220")
          }
          if (window.matchMedia("(orientation: landscape)").matches) {
            target[i].classList.add("h-500")
            target[i].classList.remove("h-220")
          }
        }
      }, false)
    })

    return {
      showModal,
      closeModal,
      ASSETS_CDN
    }
  },
}
</script>

<style scope lang="scss">
.modal-wrapper{
  padding: 0 20px;

  &.filter{
    // 85px for IOS bottom bar
    transform: translateY(calc(100% + 85px));
    transition: all 0.3s ease;
    &.active{
      transform: translateY(0%);
      transition: all 0.3s ease;
    }
  }
  &.none-filter{
    display: none;
    &.active{
      display: block;
    }
  }

  .modal-container{
    box-shadow: 0px 10px 25px rgba(164, 203, 250, 0.4);
    border-radius: 14px;
  }
}
.filter .modal-container {
  @include phone {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #FFF;
    z-index: 9999;
    top: 0;
    left: 0;
    overflow: hidden;
    flex-direction: column;
    animation-fill-mode: both;
    border-radius: 0;
  }
  .modal-body {
    @include phone {
      overflow: auto;
      flex: 1 1 auto;
    }
  }
}
.modal-mask {
  background-color: rgba(0, 0, 0, 0.35);
  display: none;
    &.active{
      display: block;
    }
}
.modal-header {
  padding: 12px 10px 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-body {
  text-align: left;
}
.modal-footer {
  //position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 20px;
  background: $white;
}
</style>
